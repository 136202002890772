import React from "react";
import Conclusion from "../../components/project/Conclusion";
import CommonProjectComponent from "../../components/project/CommonProjectComponent";
import ProblemSolution from "../../components/project/ProblemSolution";
import ProjectDetailBanner from "../../components/project/ProjectDetailBanner";
import PreviousNext from "../../components/PreviousNext";
import { Seo } from "../../components/seo";
import Layout from "../../components/layout";

const ElasticSign = () => {
  const headingbanner = {
    title: `Elastic Sign`,
    content: `A Digital Signature Platform`,
  };

  const data = {
    images: ["elastic-sign.webp", "elastic-sign-2.webp"],
    firstAlt: "UI Design Of Document Management Screen",
    firstTitle: "Document Management Screen Design",
    secondAlt: "UI UX Design To Sign Document Digitally",
    secondTitle: "E-Sign Documents",
  };

  const problemSolutionContent = {
    sidebar: [
      {
        title: "Industry",
        text: ["SaaS"],
      },
      {
        title: "What we did",
        text: ["User Research", "UI UX Designing", "React Development"],
      },
      {
        title: "Platform",
        text: ["Web Application"],
      },
    ],
    mainContent: [
      {
        title: "Problem",
        content: [
          `The traditional document signing process was time-consuming, prone to errors, and result in delays, making it essential to have a digital signature document platform that addresses these challenges.`,
        ],
      },
      {
        title: "Solution",
        content: [
          `The digital signature document platform will streamline the document signing process, enable remote signing, provide security and authentication features, and improve productivity and efficiency.`,
        ],
      },
    ],
    image: ["elastic-sign-problem.webp"],
    firstAlt: "All Mockup Screens",
    firstTitle: "Project Screens",
  };

  const projectData = {
    title: `Elastic Sign is a Digital Signature Platform`,
    para: [
      `With our platform, you can easily upload your documents, sign them digitally, and share them with others. `,
      `Our advanced security features ensure that your signatures are legally binding and tamper-proof. `,
      `Plus, our platform is easy to use and accessible from anywhere, making it a convenient solution for businesses of all sizes.`,
    ],
    content: {
      image: ["elastic-sign-projectone.webp"],
      imageAlt: "UI UX of Login Screen of a Digital Signature Platform",
      imageTitle: "Login Screen",
      text: `We created this minimalistic login screen to provide tailor-made experience to the user once they login into the platform.`,
    },
    content2: {
      image: ["elastic-sign-projectone-2.webp"],
      imageAlt: "UI Design Of Document Management Screen",
      imageTitle: "Document Management Screen Design",
      text: `This screen provides an overview of all the documents along with its status for user to offer user-friendly and intuitive experience.`,
    },
  };

  const projectData2 = {
    title: `Create Your <br />Digital Documents`,
    para: [
      `Using this platform you can use technology to create electronic versions of your paper documents. This process involves scanning, typing or uploading documents in digital formats such as PDF or Word.`,
      `By creating digital documents, you can store and access them more easily and share them with others electronically, rather than relying on physical copies. `,
    ],
    content: {
      image: ["elastic-sign-projecttwo.webp"],
      imageAlt: "UI UX Design To Sign Document Digitally",
      imageTitle: "E-Sign Documents",
    },
  };

  const projectData3 = {
    title: `Easier Way to Verify Digital Documents `,
    para: [
      `With advancements in technology, digital signatures and encryption techniques have made it easier to verify the authenticity of digital documents.`,
      `It also provides greater efficiency and convenience for businesses and individuals who rely on electronic documents for their daily operations.`,
    ],
    content: {
      image: [
        "elastic-sign-projectthree.webp",
        "elastic-sign-projectthree-2.webp",
        "elastic-sign-projectthree-3.webp",
        "elastic-sign-projectthree-4.webp",
        "elastic-sign-projectthree-5.webp",
        "elastic-sign-projectthree-6.webp",
      ],
      firstAlt: "UI Screen to upload documents for e-sign",
      firstTitle: "Upload Documents",
      secondAlt: "UI UX screen for setting up users account",
      secondTitle: "Account Setup",
      thirdAlt: "Draw, Upload and Setup digital signature",
      thirdTitle: "Your E- Sign Setup",
      fourthAlt: "Verify identity through PAN or Aadhar Card",
      fourthTitle: "Verify Identity",
      fifthAlt: "List of Contact to share digital document",
      fifthTitle: "Contacts List",
      sixthAlt: "UI UX Screen for updating profile information",
      sixthTitle: "Profile Information",
    },
  };

  const conclusionData = [
    {
      para: [
        `In conclusion, the case study on e-sign platforms has demonstrated the many benefits of using this technology in various industries. The study highlights that e-sign platforms provide a secure, convenient, and efficient way of signing documents remotely. `,
        `It has been shown that e-sign platforms significantly-`,
      ],
      list: [`Reduce Turnaround Time`, `Reduce Costs`, `Increase Accuracy`],
    },
  ];

  return (
    <Layout>
      <div className="service-page">
        <ProjectDetailBanner data={data} headingbanner={headingbanner} />
        <ProblemSolution data={problemSolutionContent} />
        <CommonProjectComponent data={projectData} mode="cream" />
        <CommonProjectComponent data={projectData2} />
        <CommonProjectComponent data={projectData3} mode="dark" />
        <Conclusion conclusionData={conclusionData} />
        <PreviousNext currentProjectId={1} />
      </div>
    </Layout>
  );
};

export default ElasticSign;

export const Head = () => (
  <Seo
    title="Elastic Sign- Octet Design Studio"
    description="A digital platform to sign documents electronically for security and effectiveness. We conducted user research, offered UI UX Designing and Reactjs Services."
  />
);
